import * as React from "react";

import Button from "../Button"
import SVGIcon from "../SVGIcon"

import css from './SearchPager.module.scss';

type Props = {
  firstItemIndex?: number;
  lastItemIndex?: number;
  pageLength?: number;
  onSetStart?: (newStart: number) => void,
  resultsCount?: number;
};

const SearchPager: React.FC<Props> = ({
  firstItemIndex,
  lastItemIndex,
  pageLength,
  onSetStart = (startNum: number) => {},
  resultsCount = 0,
}) => {
  if (resultsCount === 0) {
    return null;
  }
  const page = Math.floor(firstItemIndex / pageLength);
  return (
    <div>
      <span>
        Page {page + 1} - showing {firstItemIndex + 1} -{" "}
        {Math.min(lastItemIndex, resultsCount)} of {resultsCount}
      </span>
      {firstItemIndex > 0 && (
        <>
          <Button className={css.button} onClick={() => onSetStart(firstItemIndex - pageLength)}>
            <SVGIcon name="left" size="xs" /> Previous page
          </Button>{" "}
        </>
      )}
      {lastItemIndex < resultsCount && (
        <>
          <Button className={css.button} onClick={() => onSetStart(firstItemIndex + pageLength)}>
            Next page <SVGIcon name="right" size="xs" />
          </Button>
        </>
      )}
    </div>
  );
};

export default SearchPager;
