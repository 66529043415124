import React from "react"

import Layout from "../components/Layout"
import HeadMeta from "../components/HeadMeta"
import PageHeader from "../components/PageHeader"
import Breadcrumb from "../components/Breadcrumb"
import SearchBlock from "../components/SearchBlock"
import withLocation from "../../lib/HOC/withLocation"

const SearchPage = ({ search }) => {
  const initialQuery =
    search.search && search.search.length ? search.search : ""
  const initialLang = search.lang && search.lang.length ? search.lang : "en"
  return (
    <Layout>
      <HeadMeta pageTitle="Search" />
      <PageHeader>Search</PageHeader>
      <Breadcrumb
        route={[
          { name: "Parliamentary Papers", href: "/" },
          { name: "Search", href: "/search/" },
        ]}
      />
      <SearchBlock initialQuery={initialQuery} initialLang={initialLang} />
    </Layout>
  )
}

export default withLocation(SearchPage)
